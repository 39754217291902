<template>
  <div>
    <div class="man-title">
      <span>管线列表</span>
      <el-button @click="add" class="btn" type="primary" size="mini" :disabled="roleId == 3 || roleId == 4">创建管线</el-button>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="taskRuleName" label="润滑代号"></el-table-column>
        <el-table-column prop="staffGroupCount" label="巡视点名称"></el-table-column>
        <el-table-column prop="patrolCount" label="巡视点数量"></el-table-column>
        <el-table-column prop="deviceCount" label="巡视点设备数量"></el-table-column>
        <el-table-column prop="creator" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0" size="small" effect="dark" type="success">启用</el-tag>
            <el-tag v-else size="small" effect="dark" type="danger">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-dropdown v-if="staffId == scope.row.creatorId" placement="bottom-start" trigger="click">
              <span @click.stop="">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down"></i>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="onOff(scope.row)">{{ scope.row.status == 0 ? '禁用':'启用' }}</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-else placement="bottom-start" trigger="click">
              <span @click.stop="">
                <span class="el-dropdown-dis">
                  操作<i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown"></el-dropdown-menu>
              </span>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <page :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    details(row) {
      this.$router.push({ name: 'lubricationTaskDetail', params: {row: row}})
    },
    edit(row) {
      this.$router.push({ name: 'cycleEdit', params: {row: row}})
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("ruleDelete", {
          taskRuleId: row.id,
        }).then((res) => {
          this.loadTableData();
        });
      });
    },
    add() {
      this.$router.push('/pipeline/add')
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.$ajax.get('ruleQuery', {
        taskType: 0,
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
      })
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.btn {
  float: right;
}
</style>